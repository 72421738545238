var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drawPage"},[_c('van-popup',{attrs:{"position":"left","duration":0.3},model:{value:(_vm.$store.state.app.drawer.show),callback:function ($$v) {_vm.$set(_vm.$store.state.app.drawer, "show", $$v)},expression:"$store.state.app.drawer.show"}},[_c('div',{staticClass:"drawHead flexBothSidesVertical"},[_c('div',{staticClass:"asmallicon2"}),_c('i',{staticClass:"iconfont iconlist_left",style:({ color: '#000', 'font-size': '6vw' }),on:{"click":function($event){_vm.$store.state.app.drawer.show = false}}})]),_c('div',{staticClass:"au"},[_c('div',{staticClass:"secondMenus quotesB"},[_c('div',{staticClass:"drawContent"},[(!_vm.coinsList || _vm.coinsList.length == 0)?_c('nodata'):_vm._e(),(_vm.coinsList && _vm.coinsList.length > 0)?_c('ul',{staticClass:"drawUl"},[_c('li',{staticClass:"flexSpindleVertical"},[_c('span',[_vm._v(_vm._s(_vm.$t('other.translate69')))]),_c('span',[_vm._v(_vm._s(_vm.$t('other.translate27')))]),_c('span',[_vm._v(_vm._s(_vm.$t('other.translate82')))])]),_vm._l((_vm.coinsList),function(item,index){return _c('li',{key:index,staticClass:"flexSpindleVertical",on:{"click":function($event){return _vm.getint(item.symbol)}}},[_c('div',{staticClass:"BTCPrice"},[_c('div',{staticClass:"asmallicon2"},[_c('img',{attrs:{"src":require("../../assets/img/icon_bite.png"),"alt":""}})]),_vm._v(" "+_vm._s(item.symbol.split("/")[0])+" ")]),_c('div',{staticClass:"disCom",class:{
                  redText: item.change < 0,
                  greenText: item.change >= 0,
                }},[_c('div',{staticClass:"BTCPrice"},[_vm._v(" "+_vm._s(item.price)+" ")])]),_c('div',{staticClass:"BTCButton",class:{
                  red: item.change < 0,
                  green: item.change >= 0,
                }},[_vm._v(" "+_vm._s(item.rose)+" ")])])})],2):_vm._e()],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }