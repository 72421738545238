<template>
  <div>
    <div class="tradeHeaderWrap">
      <!-- :class="{ fixed: $store.state.app.trade.scrollTop > 56 }" -->
      <div class="tradeHeader">
        <div class="thLeft flexSpindle">
          <i class="iconfont iconlist_right" @click="$store.state.app.drawer.show = true"></i>
          <span>{{ currentCoin.coin }}/{{ currentCoin.base }}</span>
          <div v-for="(item, index) in coins.USDT" :key="index" class="dis">
            <div class="greenBox" v-if="item.symbol == currentCoin.coin + '/' + currentCoin.base" :class="{
              redBox: currentCoin.change < 0,
              greenBox: currentCoin.change >= 0,
            }">
              {{ item.rose }}
            </div>
          </div>
        </div>
        <div class="thRight flexSpindle">
          <!-- <van-dropdown-menu>
            <van-dropdown-item v-model="exchangeTable" :options="option1" @change="exchangeCut" />
          </van-dropdown-menu> -->
          <!-- <i
			  class="iconfont iconmarkets_"
			  @click="$router.push({ name: 'tradeDetail' })"
			></i> -->
          <div class="icoBox">
            <!-- <icons
				class="flexSpindle"
				name="more"
				@click.native="popMenu.show = true"
				type="ios"
			  /> -->
            <div class="imgSD" @click="
              $router.push({
                name: 'tradeDetail',
                params: { symbol: currentCoin.coin + '/' + currentCoin.base },
              })
              ">
              <img src="../../../assets/img/kxqh.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tradeBox">
      <div class="tradeLeft">
        <div class="trThead flexBothSidesVertical">
          <span>{{ $t("home.l2") }}</span><span>{{ $t("trade.number") }}</span>
        </div>
        <div :class="WTValue === 'limit' ? 'leftBoxs' : 'leftBoxHs'" v-if="showMAIOrMAI != '0'">
          <div v-if="showMAIOrMAI == '1'">
            <div class="ZXT" v-for="(item, i) in plate.askRows" :key="i">
              <div class="trThead flexBothSidesVertical">
                <span style="color:red">{{ formatPlatePrice(item.price) }}</span><span>{{ formatPlateAmount(item.amount)
                  }}</span>
              </div>
            </div>
          </div>
          <div v-if="showMAIOrMAI == '2'">
            <div class="ZXT" v-for="(item, i) in plate.bidRows" :key="i">
              <div class="trThead flexBothSidesVertical">
                <span style="color:green">{{
                  formatPlatePrice(item.price)
                }}</span><span>{{ formatPlateAmount(item.amount) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else :class="WTValue === 'limit' ? 'leftBoxs' : 'leftBoxHs'">
          <div class="leftBoxHTWO">
            <div class="ZXT" v-for="(item, i) in plate.askRows" :key="i">
              <div class="trThead flexBothSidesVertical">
                <span style="color:red">{{ formatPlatePrice(item.price) }}</span><span>{{ formatPlateAmount(item.amount)
                  }}</span>
              </div>
            </div>
          </div>
          <div class="dis" :class="{
            'hqlb-text': currentCoin.change < 0,
            'hqlb-text-green': currentCoin.change >= 0,
          }">
            {{ priceAA | toFixed(baseCoinScale) }}
            <div class="asmallicon">
              <img src="../../../assets/img/icon_fall.png" alt="" v-if="currentCoin.change < 0" />
              <img src="../../../assets/img/icon_rise.png" alt="" v-if="currentCoin.change >= 0" />
            </div>
          </div>
          <div class="leftBoxHTWO">
            <div class="ZXT" v-for="(item, i) in plate.bidRows" :key="i">
              <div class="trThead flexBothSidesVertical">
                <span style="color:green">{{
                  formatPlatePrice(item.price)
                }}</span><span>{{ formatPlateAmount(item.amount) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="sd5 disB" style="margin-top: 10px;">
          <van-dropdown-menu>
            <van-dropdown-item v-model="plate.maxPostion" :options="SDOption" @change="SDChange" />
          </van-dropdown-menu>
          <div class="imgSD" style="width: 32px;height: 32px;" @click="showPicker = true">
            <img src="../../../assets/img/sgfk.png" alt="" />
          </div>
        </div>
        <!-- <div class="zsjBox dis">
			<span class="zsj">止盈价</span>
			<van-field
			  v-model="form.buy.stopProfitPrice"
			  type="number"
			  placeholder="0.00"
			/>
		  </div> -->
      </div>
      <!-- 买卖盘 -->
      <div class="tradeRight">
        <div class="bc">
          <van-dropdown-menu>
            <van-dropdown-item v-model="WTValue" :options="WTOption" @change="chooseLimitOrMarket" />
          </van-dropdown-menu>
        </div>
        <div class="bc" v-if="WTValue === 'limit'">
          <van-stepper v-model="plate.price" decimal-length="2" />
        </div>
        <div class="bc">
          <!-- <van-field v-model="value" type="number" placeholder="数量" /> -->
          <input v-model="form.buy.limitAmount" type="number" placeholder="数量" />
          <!-- <input
			  v-else
			  v-model="form.sell.marketAmount"
			  type="number"
			  placeholder="数量"
			/> -->
          <div class="textADA">
            {{ currentCoin.coin }}
          </div>
        </div>
        <div class="disBew">
          <span>{{ $t('other.translate7') }}</span>
          <span v-if="exchangeTable === 1">{{ contractAssetPNL.balance.toFixed(4) || 0
            }}USDT</span>
          <span v-else>{{ contractAssetPNL.balanceExperience || 0
            }}{{ currentCoin.coin }}</span>
        </div>
        <div class="tradeSlider">
          <div class="vslider">
            <van-slider bar-height="0.6vw" v-model="percent"
              :active-color="$store.state.app.nightModel ? '#000' : '#333'" inactive-color="#e5e5e5"
              @input="sliderChange">
              <div slot="button" class="customButton">
                <span
                  style="background-color: #000;display: inline-block;width: 15px;height: 15px;display: flex;align-items: center;justify-content: center;">
                  <span style="background-color: #fff;display: inline-block;width: 9px;height: 9px;"></span>
                </span>
                <!--  {{ percent + '%' }} -->
                <!-- <icons name="menu" type="ios" /> -->
              </div>
            </van-slider>
          </div>
          <div class="vsliderNum">
            <span style="color: #000">0</span><span>{{ percent }}% </span>
          </div>
          <div class="tradeSliderDownI" :class="{ buying: tradeTypeBuy }">
            <i class="active"></i><i :class="{ active: percent >= 25 }"></i><i :class="{ active: percent >= 50 }"></i><i
              :class="{ active: percent >= 75 }"></i><i :class="{ active: percent >= 100 }"></i>
          </div>
          <div class="sliderNum">
            <span>0</span><span>25</span><span>50</span><span>75</span><span>100</span>
          </div>
        </div>
        <div class="trThead martop">
          <span>{{ $t('other.translate8') }} {{ Short }}USDT</span>
        </div>
        <div class="pt3">
          <van-button size="large" class="tradeSubmitMore" type="info" @click="buyBibi">{{ $t('other.translate51')
            }}</van-button>
          <van-button size="large" class="tradeSubmitMore"
            style="margin-top: 8px;background-color: transparent;border: 1px solid #000000;color: #000" type="danger"
            @click="sellBibi">{{ $t('other.translate52') }}</van-button>
        </div>
        <!-- <div class="disBew2">
			<div class="hh">
			  <div
				:class="{
				  activeBGC: selectBuyLever == item,
				  bor1: selectBuyLever != item,
				}"
				@click="buyLever(item)"
				v-for="item in currentCoin.levers"
				:key="item"
			  >
				{{ item }}
			  </div>
			</div>
		  </div> -->
        <!-- <div class="zsjBox dis">
			<span class="zsj">止损价</span>
			<van-field
			  v-model="form.buy.stopLossPrice"
			  type="number"
			  placeholder="0.00"
			/>
		  </div> -->
      </div>
    </div>
    <div class="btn" v-if="!islogin" @click="goToLogin">{{ $t('other.translate6') }}</div>
    <div class="tradeContent">
      <div class="tradeTitle flexBothSidesVertical">
        <span class="dqcc">{{ $t('other.translate22') }}<span class="dqccLine"></span></span>
        <span @click="clickMore()" style="font-size: 14px;color: #000">{{ $t('other.translate23') }} ></span>
      </div>
      <div class="tradeHistory">
        <!-- <loading /> -->
        <!-- <nodata v-if="currentOrder.rows.length == 0"></nodata> -->
        <van-empty class="custom-image" image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
          :description="$t('other.translate58')" v-if="!currentOrder.rows || currentOrder.rows.length == 0" />
        <ul class="assetsList" v-else>
          <li v-for="(item, idx) of currentOrder.rows" :key="idx" class="li">
            <div class="forTitle">
              <span>{{ item.symbol }}</span>
              <div class="type" :style="{ backgroundColor: item.direction == 'BUY' ? '#3AD29F' : '#EB4335' }">
                {{ item.direction == "BUY" ? $t('other.translate51') : $t('other.translate52') }}
              </div>
            </div>
            <div class="time">
              {{ formatTimestamp(item.time) }}
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate32') }}</div>
              <div class="">{{ followTypeFormat(item) }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate53') }}</div>
              <div class="">{{ item.amount }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate54') }}</div>
              <div class="">{{ $t('other.translate78') }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate29') }}</div>
              <div class="">{{ $t('other.translate79') }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate26') }}</div>
              <div class="">{{ item.amount }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate55') }}</div>
              <div class="">{{ item.turnover }}</div>
            </div>
            <div class="boxOne">
              <div class="">{{ $t('other.translate56') }}</div>
              <div class="">{{ item.price }}</div>
            </div>
            <div class="disCenter">
              <div class="xgButton" @click="cancel(idx)">{{ $t('other.translate57') }}</div>
            </div>
          </li>
          <!-- <li v-for="(item, idx) of currentOrder.rows" :key="idx" class="li">
            <div class="forTitle">
              <div class="types" :class="item.direction == 'BUY' ? 'greens' : 'reds'">
                {{ item.direction == "BUY" ? "买入" : "卖出" }}
              </div>
              {{ item.symbol }}
            </div>
            <div class="forTitle">
              {{ formatTimestamp(item.time) }}
            </div>
            <div class="boxOne">
              <div class="">订单类型</div>
              <div class="">{{ followTypeFormat(item) }}</div>
            </div>
            <div class="boxOne">
              <div class=""></div>
              <div class=""></div>
            </div>
            <div class="boxOne">
              <div class="">数量</div>
              <div class="">{{ item.amount }}</div>
            </div>
            <div class="boxOne">
              <div class="">状态</div>
              <div class="">{{ "已完成" }}</div>
            </div>
            <div class="boxOne">
              <div class=""></div>
              <div class=""></div>
            </div>
            <div class="boxOne">
              <div class="">支付方式</div>
              <div class="">{{ "资金" }}</div>
            </div>
            <div class="boxOne">
              <div class="">成交量</div>
              <div class="">{{ item.amount }}</div>
            </div>
            <div class="boxOne">
              <div class="">成交额</div>
              <div class="">{{ item.turnover }}</div>
            </div>
            <div class="boxOne">
              <div class="">委托价</div>
              <div class="">{{ item.price }}</div>
            </div>
            <div class="disCenter">
              <van-button size="large" type="info" class="xgButton" plain round hairline
                @click="cancel(idx)">撤销</van-button>
            </div>
          </li> -->
        </ul>
      </div>
    </div>
    <van-action-sheet v-model="popMenu.show" :actions="popMenu.actions" @select="onMenuSelect" />
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar :columns="columns" :cancel-button-text="$t('other.translate118')"
        :confirm-button-text="$t('button.assets.sure')" @confirm="onConfirm" @cancel="showPicker = false" />
    </van-popup>
    <van-dialog v-model="showModifyStop" :title="$t('other.translate13')" show-cancel-button
      @confirm="modifyStopProfitLoss">
      <div class="disPadd">
        <van-field v-model="form.modifyStop.stopProfitPrice" label="止盈" placeholder="0.0" />
        <van-field v-model="form.modifyStop.stopLossPrice" label="止损" placeholder="0.0" />
      </div>
    </van-dialog>
  </div>
</template>
<script>
import number from "@/views/modules/number.vue";
let SockJS = require("sockjs-client");
let Stomp = require("stompjs");
// import Datafeeds from '@js/charting_library/datafeed/bitrade.js';
import { Toast } from "vant";

import { mapState } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      paramsIsaac: {},
      paramsIsaacAll: {},
      trade: {
        rows: [],
      },
      buySellCoinNum: 1,
      showModifyStop: false,
      selectBuyLever: "onea",
      contractAssetPNL: {
        dynamicProfit: 0.0,
        balance: 0.0,
        available: 0.0,
        frozon: 0.0,
        floatPnL: 0.0,
        dynamicProfitPercent: 0.0,
        riskRate: 0.0,
      },
      tskb: "tskb",
      wallet: {
        base: 0.0,
        coin: 0.0,
      },
      currentOrder: {
        rows: [],
      },
      historyOrder: {
        pageSize: 10,
        total: 10,
        page: 1,
        rows: [],
      },
      holdOrder: {
        pageSize: 10,
        total: 10,
        page: 1,
        rows: [],
      },
      closeOrder: {
        pageSize: 10,
        total: 10,
        page: 1,
        rows: [],
      },
      showPicker: false,
      exchangeTable: 1,
      columns: [this.$t('other.translate17'), this.$t('other.translate18'), this.$t('other.translate19')],
      limitTab: true,
      wallet: {
        base: 0.0,
        coin: 0.0,
        baseCompany: "", //基础币单位
        coinCompany: "", // 卖出币单位
      },
      form: {
        buy: {
          limitPrice: 0.0,
          limitAmount: 0.0,
          marketAmount: 0.0,
          limitTurnover: 0.0,
          lever: 0,
          stopProfitPrice: 0.0,
          stopLossPrice: 0.0,
          // stopPrice: 0.0,
          // stopBuyPrice: 0.0,
          // stopBuyAmount: 0.0,
          // stopTurnover: 0.0
        },
        sell: {
          limitPrice: 0.0,
          limitAmount: 0.0,
          marketAmount: 0.0,
          limitTurnover: 0.0,
          lever: 0,
          stopProfitPrice: 0.0,
          stopLossPrice: 0.0,
          // stopPrice: 0.0,
          // stopBuyPrice: 0.0,
          // stopBuyAmount: 0.0,
          // stopTurnover: 0.0
        },
        modifyStop: {
          stopProfitPrice: 0.0,
          stopLossPrice: 0.0,
        },
      },
      showMAIOrMAI: 0,
      coins: [],
      currentCoin: {
        base: "",
        coin: "",
        symbol: "",
        levers: [],
      },
      baseCoinScale: 2,
      value9: 0,
      plate: {
        maxPostion: 5,
        askRows: [],
        bidRows: [],
        price: 0.0,
      },
      priceAA: 0.0,
      defaultPath: "btc_usdt",
      numberIndex: 1,
      tradeTypeBuy: true,
      submiting: false,
      popMenu: {
        show: false,
        actions: [
          {
            name: this.$t("assets.index.n1"),
            value: "deposit",
          },
          {
            name: this.$t("assets.index.n3"),
            value: "transfer",
          },
          {
            name: this.$t("trade.add") + "/" + this.$t("trade.cancelChoice"),
            value: "fav",
          },
        ],
      },
      percent: 0,
      forms: {
        // 表单
        total: 0,
        price: "",
        number: "",
      },
      den: 1, // 分母 最大买卖盘
      actualBase: 0,
      actualProduct: 0,
      c: null,
      lastTick: {}, // 记录上一次tick
      showAll: true,
      option1: [
        { text: "资金交易", value: 1 },
        { text: "体验金交易", value: 2 },
      ],
      WTValue: "limit",
      WTOption: [
        { text: this.$t('other.translate20'), value: "limit" },
        { text: this.$t('other.translate21'), value: "market" },
      ],
      SDOption: [
        { text: this.$t('other.translate14'), value: 5 },
        { text: this.$t('other.translate15'), value: 10 },
        { text: this.$t('other.translate16'), value: 15 },
      ],
      num: 0
    };
  },
  components: {
    number,
  },
  watch: {
    // percent: function(cur, old) {
    //   let scale = Number(cur / 100);
    //   let number = Number(this.actualProduct) * scale;
    //   if (this.tradeTypeBuy) {
    //     // 如果是买
    //     // 折算可买数量
    //     if (this.forms.price) {
    //       number = Number(this.actualBase / this.forms.price) * scale;
    //     }
    //   }
    //   // 滚动到最左边 设置为最小值
    //   if (number < this.minNumber) {
    //     number = this.minNumber;
    //   }
    //   if (this.tradeTypeBuy) {
    //     number = this.actualBase > 0 ? number : "";
    //   } else {
    //     number = this.actualProduct > 0 ? number : "";
    //   }
    //   this.forms.number = this.$tdp.fmtZero(number, this.digit);
    // },
    app: {
      handler(newValue, oldValue) {
        if (newValue.drawer.toptype) {
          this.init(newValue.drawer.toptype);
        }
      },
      deep: true,
    },
    "$store.state.symbol": function (cur, old) {
      this.den = 0;
    },
    "$store.state.bidask": {
      handler: function (cur, old) {
        if (!cur) {
          this.calculation();
          this.$store.state.bidask = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(["app"]), // 获取整个对象
    member: function () {
      return this.$store.getters.member;
    },
    Short() {
      var num = this.form.buy.limitAmount;
      var price = this.plate.price;
      var multiple = this.selectBuyLever;
      var floor = this.coinScale;
      return (num * price).toFixed(2);
    },
    islogin() {
      return this.$store.state.isLogin;
    },
    coin() {
      return this.$mts.getVs(this.$store.state.symbol, 0);
    },
    market() {
      return this.$mts.getVs(this.$store.state.symbol, 1);
    },
    // wallet() {
    //   // 当前交易货币钱包
    //   let w = this.$store.state.ws.capital;
    //   if (!w || this.$mts.isEmpty(w)) {
    //     return false;
    //   }
    //   return w;
    // },
    base() {
      // 基础货币
      let that = this;
      if (!that.wallet || !that.wallet[that.market]) {
        return "--";
      }
      let w = that.wallet[that.market];
      that.actualBase = w.usable;
      return that.$tdp.meter(w.usable, that.digit);
    },
    product() {
      // 商品货币
      let that = this;
      if (!that.wallet) {
        return "--";
      }
      let w = that.wallet[that.coin];
      that.actualProduct = w.usable;

      return that.$tdp.meter(w.usable, that.digit);
    },
    tick() {
      // 交易行情
      let that = this;
      let tick = that.$store.state.ws.tick;
      if (tick) {
        return tick;
      }
      return { close: 0 };
    },
    money() {
      return this.$tdp.money(this.forms.price, this.market);
    },
    closeMoney() {
      return this.$tdp.money(this.price, this.market);
    },
    // current() {
    //   // 当前交易货币配置
    //   if (!this.market || !this.$store.state.coin) {
    //     return null;
    //   }
    //   return this.$store.state.coin[this.market];
    // },
    // trade() {
    //   if (!this.coin || !this.$store.state.coin) {
    //     return null;
    //   }
    //   return this.$store.state.coin[this.coin];
    // },
    digit() {
      let token = this.current;
      if (token && token.digit) {
        return token.digit[0];
      }
      return 8;
    },
    step() {
      return Math.pow(0.1, this.digit);
    },
    maxNumber() {
      if (this.trade) {
        if (this.islogin) {
          // 登录状态
          if (this.tradeTypeBuy) {
            let base = this.actualBase;
            if (this.forms.price) {
              let max = this.$tdp.fmtZero(
                Number(base / this.forms.price),
                this.digit
              );

              return Number(max);
            } else {
              return base;
            }
          } else {
            return this.actualProduct;
          }
        }
        return this.trade.maxbuy;
      }

      return 0;
    },
    minNumber() {
      if (this.trade) {
        if (this.islogin && this.tradeTypeBuy) {
          return this.actualBase > 0 ? this.trade.minbuy : 0;
        }
        if (this.islogin && !this.tradeTypeBuy) {
          return this.actualProduct > 0 ? this.trade.minbuy : 0;
        }
        return this.trade.minbuy;
      }
      return 0;
    },
    total() {
      // 交易总额
      let price = this.forms.price;
      let number = this.forms.number;
      if (this.percent == 100) {
        return this.tradeTypeBuy
          ? this.actualBase
          : this.actualProduct * this.forms.price;
      }
      return this.$tdp.toThousands(
        this.$tdp.fmtZero(Number(price * number), this.digit)
      );
    },
    buy() {
      // 买盘数据
      let that = this;
      let list = [];
      let buys = that.$store.state.ws.buys;
      if (buys && buys[that.digit]) {
        list = buys[that.digit];
        // 获取最大值
        for (let idx = 0; idx < list.length; idx++) {
          if (that.den < Number(list[idx].total)) {
            that.den = list[idx].total;
          }
          // 设置初始默认值
          if (idx == 0 && !that.tradeTypeBuy && !that.forms.price) {
            that.forms.price = that.$tdp.fmtZero(list[idx].price);
          }
        }
      }
      return list;
    },
    sell() {
      // 卖盘数据

      let that = this;
      let list = [];
      let sells = that.$store.state.ws.sells;

      if (sells && sells[that.digit]) {
        list = sells[that.digit];
        // 获取最大值
        for (let idx = 0; idx < list.length; idx++) {
          if (that.den < Number(list[idx].total)) {
            that.den = list[idx].total;
          }
          if (
            idx == list.length - 1 &&
            that.tradeTypeBuy &&
            !that.forms.price
          ) {
            that.forms.price = that.$tdp.fmtZero(list[idx].price);
          }
        }
      }
      return list;
    },
    order() {
      // 委托订单
      let that = this;
      if (that.$store.state.ws.actives) {
        let list = that.$store.state.ws.actives.list;
        let total = that.$store.state.ws.actives.total;
        if (total >= list.length) {
          that.showAll = false;
        }
        return list;
      }
      return [];
    },
    nodata() {
      // 无数据
      if (this.order.length == 0 || !this.islogin) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    // this.$mts.rates();
    console.log("ppp", this.islogin);
  },
  created() {
    this.$bus.$on("funcBus", (data) => {
      this.close(data);
    });
    this.$bus.$on("showModifyStop", (data) => {
      this.modifySelectOrder = data;
      this.form.modifyStop.stopProfitPrice = data.stopProfitPrice;
      this.form.modifyStop.stopLossPrice = data.stopLossPrice;
      this.modifyStopProfitLoss();
    });

    this.getdefaultSymbol().then((res) => {
      this.defaultPath = res;
      this.init();
    });
    // this.chushihua();
  },
  beforeDestroy() {
    clearInterval(this.c);
  },
  methods: {
    choose(index) {
      this.num = index
    },
    followTypeFormat(row, column) {
      if (row.type == "LIMIT_PRICE") {
        return this.$t('other.translate80');
      } else {
        return this.$t('other.translate81');
      }
    },
    getdefaultSymbol() {
      return this.$http
        .get(this.host + "/market/spot-market/default/symbol")
        .then((res) => {
          const data = res.body;
          console.log("默认的交易币对", data);
          if (data.code == 0) {
            return new Promise((resolve, reject) => {
              resolve(data.data.web);
            }).catch((reject) => reject("BTC_USDT"));
          }
        });
    },

    onMenuSelect(item) {
      switch (item.value) {
        case "deposit":
          this.$router.push({
            name: "deposit",
          });
          break;
        case "transfer":
          this.$router.push({
            name: "transfer",
          });
          break;
        case "fav":
          if (this.$store.state.isLogin) {
            const that = this;
            that.$mts.post({
              url: "user/optional",
              data: {
                symbol: that.coin + "_" + that.market,
              },
              success(response) {
                if (response.data.code == 200) {
                  that.$mts.favor(() => {
                    that.$toast(that.$t("validate.success"));
                  });
                } else {
                  // 失败
                  that.$toast(response.data.message);
                }
              },
            });
          } else {
            // 去登陆
            this.$router.push({
              name: "signin",
              query: {
                path: "trade/" + that.coin + "_" + that.market,
              },
            });
          }
          break;
      }
    },

    clickMore() {
      if (!this.paramsIsaac.symbol || !this.paramsIsaac.pageSize) {
        return;
      }
      this.$store.commit("SET_HISTORY_ORDER_ALL", this.historyOrder);
      this.$store.commit("setCurrentOrderAlls", this.currentOrder);
      this.$store.commit("setParamsDataIsaac", this.paramsIsaac);
      this.$store.commit("setParamsDataIsaacAll", this.paramsIsaacAll);
      // this.$store.state.historyOrderAll = this.historyOrder;
      // this.$store.state.currentOrderAll = this.currentOrder;
      // this.$router.push({
      //   name: "otherOrder",
      // });

      this.$mts.goto("otherOrder", this.paramsIsaac);
      // this.$mts.goto("allorders", this.paramsIsaac);
    },
    getCNYRate() {
      this.$http
        .post(this.host + "/market/exchange-rate/usd-cny")
        .then((response) => {
          let resp = response.body;
          this.CNYRate = resp.data;
          console.log("CNYRate", resp);
        });
    },
    getSymbolScale() {
      //获取精度
      this.$http
        .post(this.host + this.api.market.spotSymbolInfo, {
          symbol: this.currentCoin.symbol,
        })
        .then((response) => {
          let resp = response.body;
          if (resp != null) {
            this.currentCoin.coinScale = resp.coinScale;
            this.currentCoin.baseCoinScale = resp.baseCoinScale;
            this.baseCoinScale = resp.baseCoinScale;
            this.coinScale = resp.coinScale;

            this.symbolFee = resp.fee;

            this.enableMarketBuy = resp.enableMarketBuy;
            this.enableMarketSell = resp.enableMarketSell;

            this.exchangeable = resp.exchangeable;
          }
        });
    },
    SDChange() {
      this.init();
    },
    init(selectSymbol) {
      var params = this.$route.params[0];
      console.log(params, '90几年内');
      //  let params = this.$route.params.pathMatch;
      if (params == undefined) {
        if (selectSymbol) {
          params = selectSymbol.replace(/\//, "_");
        }
      }
      if (params == undefined) {
        // this.$router.push("/exchange/" + this.defaultPath);
        params = this.defaultPath;
      }
      const basecion = params.split("_")[1];
      if (basecion) {
        this.basecion = basecion.toLowerCase();
      }
      let coin = params.toUpperCase().split("_")[0];
      let base = params.toUpperCase().split("_")[1];
      this.currentCoin.symbol = coin + "/" + base;
      this.currentCoin.coin = coin;
      this.currentCoin.base = base;
      this.getCNYRate();
      this.getSymbolScale();
      this.getSymbol(); //包含 K线图、getFavor、startWebsock等
      this.getPlate(); //买卖盘
      this.getUseCoin(); // 可选交易币

      // this.getPlateFull(); //深度图
      this.getTrade();
      if (this.islogin) {
        this.getWallet(); //账户资产信息
        this.getCurrentOrder(); //当前委托
        this.getHistoryOrder(); //历史委托
      }
      this.sliderBuyLimitPercent = 0;
      this.sliderSellLimitPercent = 0;
      this.sliderBuyMarketPercent = 0;
      this.sliderSellMarketPercent = 0;
      this.sliderBuyStopPercent = 0;
      this.sliderSellStopPercent = 0;
    },

    // 获取可选的交易币
    getUseCoin() {
      this.$http
        .get(this.host + this.api.market.zone, {})
        .then((res) => {
          // console.log('可选的交易币', res)
          // 1、转换成大写：toUpperCase()
          // 2、转换成小写：toLowerCase()
          if (!res.code) {
            res.body.data &&
              res.body.data.forEach((item) => {
                this.useBibi = [];
                this.useBibi.push({
                  upCase: item,
                  lowCase: item.toLowerCase(),
                });
              });
            console.log("可用的交易币", this.useBibi);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTrade() {
      let params = {};
      params["symbol"] = this.currentCoin.symbol;
      params["size"] = 16;
      this.$http
        .post(this.host + this.api.market.spotTrade, params)
        .then((response) => {
          this.trade.rows = [];
          let resp = response.body;
          for (let i = 0; i < resp.length; i++) {
            this.trade.rows.push(resp[i]);
          }
        });
    },
    getCoin(symbol) {
      return this.coins._map[symbol];
    },
    startWebsock() {
      // this.loadingButton7 = true;
      if (this.stompClient) {
        this.stompClient.ws.close();
      }
      let stompClient = null;
      let that = this;
      let socket = new SockJS(that.host + that.api.market.ws);
      stompClient = Stomp.over(socket);
      this.stompClient = stompClient;
      stompClient.debug = false;
      // this.getKline();
      stompClient.connect({}, function (frame) {
          // that.datafeed = new Datafeeds.WebsockFeed(that.host + '/market/spot-market', that.currentCoin, stompClient, that.baseCoinScale);
          console.log(that.baseCoinScale,"参数");
          that.getKline();
          //订阅价格变化消息
          stompClient.subscribe('/topic/spot-market/thumb', function (msg) {
              // console.log(msg,"实时更新数据");
              var resp = JSON.parse(msg.body);
              var coin = that.getCoin(resp.symbol);
              console.log(resp,"123");
              if (coin != null) {
                  // coin.price = resp.close.toFixed(2);
                  coin.price = resp.close;
                  coin.rose = resp.chg > 0 ? '+' + (resp.chg * 100).toFixed(2) + '%' : (resp.chg * 100).toFixed(2) + '%';
                  // coin.close = resp.close.toFixed(2);
                  // coin.high = resp.high.toFixed(2);
                  // coin.low = resp.low.toFixed(2);
                  coin.close = resp.close;
                  coin.high = resp.high;
                  coin.low = resp.low;
                  coin.turnover = parseInt(resp.volume);
                  coin.volume = resp.volume;
                  coin.usdRate = resp.usdRate;
              }
          });
          //订阅实时成交信息
          stompClient.subscribe('/topic/spot-market/trade/' + that.currentCoin.symbol, function (msg) {
              var resp = JSON.parse(msg.body);
              console.log('实时成交信息：', resp);
              if (resp.length > 0) {
                  for (var i = 0; i < resp.length; i++) {
                      that.trade.rows.unshift(resp[i]);
                  }
              }
              if (that.trade.rows.length > 16) {
                  that.trade.rows = that.trade.rows.slice(0, 16);
              }
          });
          if (that.isLogin) {
              //订阅委托取消信息
              stompClient.subscribe('/topic/spot-market/order-canceled/' + that.currentCoin.symbol + '/' + that.member.id, function (msg) {
                  var resp = JSON.parse(msg.body);
                  that.refreshAccount();
              });
              //订阅委托交易完成
              stompClient.subscribe('/topic/spot-market/order-completed/' + that.currentCoin.symbol + '/' + that.member.id, function (msg) {
                  var resp = JSON.parse(msg.body);
                  that.refreshAccount();
              });
              //订阅委托部分交易
              stompClient.subscribe('/topic/spot-market/order-trade/' + that.currentCoin.symbol + '/' + that.member.id, function (msg) {
                  var resp = JSON.parse(msg.body);
                  that.refreshAccount();
              });
          }

          //订阅盘口消息
          stompClient.subscribe('/topic/spot-market/trade-plate/' + that.currentCoin.symbol, function (msg) {
              var resp = JSON.parse(msg.body);
              // console.log('', msg.body)
              // that.plate.price = resp.price;
              {
                  let sellTradePlate = resp.sellTradePlate;
                  let asks = sellTradePlate.items;
                  that.plate.askRows = [];
                  let totle = 0;
                  for (let i = that.plate.maxPostion - 1; i >= 0; i--) {
                      let ask = {};
                      if (i < asks.length) {
                          ask = asks[i];
                      } else {
                          ask['price'] = 0;
                          ask['amount'] = 0;
                      }
                      ask.direction = 'SELL';
                      ask.position = i + 1;
                      that.plate.askRows.push(ask);
                  }
                  for (let i = that.plate.askRows.length - 1; i >= 0; i--) {
                      if (i == that.plate.askRows.length - 1 || that.plate.askRows[i].price == 0) {
                          that.plate.askRows[i].totalAmount = that.plate.askRows[i].amount;
                      } else {
                          that.plate.askRows[i].totalAmount = that.plate.askRows[i + 1].totalAmount + that.plate.askRows[i].amount;
                      }
                      totle += that.plate.askRows[i].amount;
                  }
                  that.plate.askTotle = totle;
              }
              {
                  let buyTradePlate = resp.buyTradePlate;
                  let bids = buyTradePlate.items;
                  that.plate.bidRows = [];
                  let totle = 0;
                  for (let i = 0; i < that.plate.maxPostion; i++) {
                      let bid = {};
                      if (i < bids.length) {
                          bid = bids[i];
                      } else {
                          bid['price'] = 0;
                          bid['amount'] = 0;
                      }
                      bid.direction = 'BUY';
                      bid.position = i + 1;
                      that.plate.bidRows.push(bid);
                  }
                  for (let i = 0; i < that.plate.bidRows.length; i++) {
                      if (i == 0 || that.plate.bidRows[i].amount == 0) {
                          that.plate.bidRows[i].totalAmount = that.plate.bidRows[i].amount;
                      } else {
                          that.plate.bidRows[i].totalAmount = that.plate.bidRows[i - 1].totalAmount + that.plate.bidRows[i].amount;
                      }
                      totle += that.plate.bidRows[i].amount;
                  }
                  that.plate.bidTotle = totle;
              }
              // console.log('订阅的盘口信息：', this.plate)
          });
      });
    },
    computePercent(row) {
      var width = 0;
      var totle =
        row.direction === "BUY" ? this.plate.bidTotle : this.plate.askTotle;
      if (row.totalAmount) {
        width = (row.totalAmount / totle).toFixed(4) * 100 + "%";
      }
      return width;
    },
    formatPlatePrice(price) {
      if (price == 0) {
        return "--";
      } else {
        return price.toFixed(this.baseCoinScale);
      }
    },
    buyLever(val) {
      this.selectBuyLever = val;
      this.form.buy.lever = val;
    },
    formatPlateAmount(amount) {
      if (amount == 0) {
        return "--";
      } else {
        return amount.toFixed(this.coinScale);
      }
    },
    getSymbol() {
      this.$http
        .post(this.host + this.api.market.spotThumb, {})
        .then((response) => {
            var resp = response.body;
            console.log('得到交易对信息', resp);
            //先清空已有数据
            for (var i = 0; i < resp.length; i++) {
                var coin = resp[i];
                coin.base = resp[i].symbol.split('/')[1];
                this.coins[coin.base] = [];
                this.coins[coin.base + '2'] = [];
                this.coins._map = [];
                this.coins.favor = [];
            }
            for (var i = 0; i < resp.length; i++) {
                var coin = resp[i];
                coin.price = resp[i].close = resp[i].close.toFixed(this.baseCoinScale);
                coin.rose = resp[i].chg > 0 ? '+' + (resp[i].chg * 100).toFixed(2) + '%' : (resp[i].chg * 100).toFixed(2) + '%';
                coin.coin = resp[i].symbol.split('/')[0];
                coin.base = resp[i].symbol.split('/')[1];
                coin.href = (coin.coin + '_' + coin.base).toLowerCase();
                coin.isFavor = false;
                this.coins._map[coin.symbol] = coin;
                console.log('coin', coin);
                if (coin.zone == 0 && coin.exchangeable == 1) {
                    //
                    this.coins[coin.base].push(coin);
                } else {
                    this.coins[coin.base + '2'].push(coin);
                }
                this.$store.state.coinsList = this.coins[coin.base];
                if (coin.symbol == this.currentCoin.symbol) {
                    this.currentCoin = coin;
                    this.form.buy.limitPrice = this.form.sell.limitPrice = coin.price; // 限价 买入卖出的
                    console.log('限价价格', coin.price);
                }
            }
            console.log('coinsssssss', this.coins);
            if (this.isLogin) {
                this.getFavor();
            }

          this.startWebsock();
        });
    },
    exchangeCut(type) {
      this.exchangeTable = type;
    },
    getSymbolLeverage(symbol) {
      let param = { symbol: symbol };
      //获取杠杆
      this.$http
        .post(this.host + this.api.exchange.getLeverage, param)
        .then((response) => {
          let result = response.body;
          if (result.code == 0 && result.data.length > 0) {
            this.selectBuyLever = result.data[0];
            this.selectSellLever = result.data[0];
            this.form.buy.lever = this.selectBuyLever;
            this.form.sell.lever = this.selectSellLever;
            this.currentCoin.levers = result.data;
          }
        });
    },
    chooseLimitOrMarket(val) {
      if (val == "market") {
        this.buySellCoinNum = 2;
        this.limitTab = false;
      } else {
        this.limitTab = true;
        this.buySellCoinNum = 1;
      }
    },
    getPlate() {
      //买卖盘
      let params = {};
      params["symbol"] = this.currentCoin.symbol;
      this.$http
        .post(this.host + this.api.market.spotPlatemini, params)
        .then((response) => {
          this.plate.askRows = [];
          this.plate.bidRows = [];
          let resp = response.body;
          console.log("买卖盘：", resp);

          if (resp.ask && resp.ask.items) {
            for (let i = 0; i < resp.ask.items.length; i++) {
              if (i == 0)
                resp.ask.items[i].totalAmount = resp.ask.items[i].amount;
              else
                resp.ask.items[i].totalAmount =
                  resp.ask.items[i - 1].totalAmount + resp.ask.items[i].amount;
            }
            for (let i = 0; i < resp.ask.items.length; i++) {
              let ask = resp.ask.items[i];
              ask.direction = "SELL";
              ask.position = i + 1;
              this.plate.askRows.unshift(ask);
              if (i == this.plate.maxPostion - 1) break;
            }
            if (resp.ask.items.length < this.plate.maxPostion) {
              for (
                let i = resp.ask.items.length;
                i < this.plate.maxPostion;
                i++
              ) {
                let ask = { price: 0, amount: 0 };
                ask.direction = "SELL";
                ask.position = i + 1;
                ask.totalAmount = 0;
                this.plate.askRows.unshift(ask);
              }
              const rows = this.plate.askRows,
                len = rows.length,
                totle = rows[resp.ask.items.length - 1]
                  ? rows[resp.ask.items.length - 1].totalAmount
                  : 0;
              // if(rows[resp.ask.items.length - 1]!=undefined){
              //     const totle = rows[resp.ask.items.length - 1].totalAmount;
              // }else{
              //     let totle;
              // }
              this.plate.askTotle = totle;
            } else {
              const rows = this.plate.askRows,
                len = rows.length,
                totle = rows[len - 1].totalAmount;
              this.plate.askTotle = totle;
            }
            //this.plate.askRows = this.plate.bidRows.slice(0,this.plate.maxPostion);

            console.log("plate", this.plate);
          }

          if (resp.bid && resp.bid.items) {
            for (let i = 0; i < resp.bid.items.length; i++) {
              if (i == 0)
                resp.bid.items[i].totalAmount = resp.bid.items[i].amount;
              else
                resp.bid.items[i].totalAmount =
                  resp.bid.items[i - 1].totalAmount + resp.bid.items[i].amount;
            }
            for (let i = 0; i < resp.bid.items.length; i++) {
              let bid = resp.bid.items[i];
              bid.direction = "BUY";
              bid.position = i + 1;
              this.plate.bidRows.push(bid);
              if (i == this.plate.maxPostion - 1) break;
            }
            if (resp.bid.items.length < this.plate.maxPostion) {
              for (
                let i = resp.bid.items.length;
                i < this.plate.maxPostion;
                i++
              ) {
                let bid = { price: 0, amount: 0 };
                bid.direction = "BUY";
                bid.position = i + 1;
                bid.totalAmount = 0;
                this.plate.bidRows.push(bid);
              }
              const rows = this.plate.bidRows,
                len = rows.length,
                totle = rows[resp.bid.items.length - 1]
                  ? rows[resp.bid.items.length - 1].totalAmount
                  : 0;
              // if(rows[resp.bid.items.length - 1]!=undefined){
              //     const totle = rows[resp.bid.items.length - 1].totalAmount;
              // }else{
              //     let totle;
              // }
              this.plate.bidTotle = totle;
            } else {
              const rows = this.plate.bidRows,
                len = rows.length,
                totle = rows[len - 1].totalAmount;
              this.plate.bidTotle = totle;
            }
            //this.plate.bidRows = this.plate.bidRows.slice(0,this.plate.maxPostion);

            console.log("plate", this.plate);
          }
        });
    },
    // 买币币
    buyBibi() {
      if (this.buySellCoinNum == 1) {
        console.log("限价买入");
        this.buyWithLimitPrice();
      }
      if (this.buySellCoinNum == 2) {
        console.log("市价买入");
        this.buyWithMarketPrice();
      }
    },
    // 卖币币
    sellBibi() {
      if (this.buySellCoinNum == 1) {
        console.log("限价卖出");
        this.sellLimitPrice();
      }
      if (this.buySellCoinNum == 2) {
        console.log("市价卖出");
        this.sellMarketPrice();
      }
    },
    //限价买入
    buyWithLimitPrice() {
      if (this.form.buy.limitAmount == "") {
        // this.$Message.error(this.$t('exchange.buyamounttip'));
        Toast.fail(this.$t('other.translate125'));
        return;
      }
      var maxAmount = this.wallet.base / this.form.buy.limitPrice;
      console.log(this.form.buy.limitPrice, "this.form.buy.limitPrice");
      if (this.form.buy.limitAmount > maxAmount) {
        // this.$Message.error(
        //   this.$t("exchange.buyamounttipwarning") + this.toFloor(maxAmount)
        // );
        Toast.fail(this.$t('other.translate127') + maxAmount);

        return;
      }
      var that = this;
      var params = {};
      params["symbol"] = this.currentCoin.symbol;
      params["price"] = this.form.buy.limitPrice;
      params["amount"] = this.form.buy.limitAmount;
      params["direction"] = "BUY";
      params["type"] = "LIMIT_PRICE";
      params["useDiscount"] = this.isUseBHB ? "1" : "0"; //是否试用手续费抵扣,0 不使用 1使用
      if (this.exchangeTable === 1) {
        params["fundsType"] = "1";
      } else {
        params["fundsType"] = "0"; // 体验金
      }
      this.$http
        .post(this.host + this.api.exchange.spotOrderAdd, params)
        .then((response) => {
          var resp = response.body;
          if (resp.code == 0) {
            // this.$Message.success(that.$t("exchange.success"));
            Toast.success(this.$t('other.translate123'));
            this.getWallet();
            this.getCurrentOrder();
            this.getHistoryOrder();
            this.form.buy.limitAmount = 0;
            this.sliderBuyLimitPercent = 0;
          } else {
            // this.$Message.error(resp.message);
            Toast.fail(resp.message);
          }
        });
    },
    buyWithMarketPrice() {
      if (this.form.buy.limitAmount == "") {
        // this.$Message.error(this.$t("exchange.pricetip"));
        Toast.fail(this.$t('other.translate125'));
        return;
      }
      if (this.form.buy.limitAmount > parseFloat(this.wallet.base)) {
        // this.$Message.error(
        //   this.$t("exchange.pricetipwarning") + this.wallet.base
        // );
        Toast.fail(this.$t('other.translate126') + this.wallet.base);

        return;
      }
      var params = {};
      params["symbol"] = this.currentCoin.symbol;
      params["price"] = 0;
      params["amount"] = this.form.buy.limitAmount;
      params["direction"] = "BUY";
      params["type"] = "MARKET_PRICE";
      params["useDiscount"] = this.isUseBHB ? "1" : "0"; //是否试用手续费抵扣,0 不使用 1使用
      if (this.exchangeTable === 1) {
        params["fundsType"] = "1";
      } else {
        params["fundsType"] = "0"; // 体验金
      }
      var that = this;
      this.$http
        .post(this.host + this.api.exchange.spotOrderAdd, params)
        .then((response) => {
          var resp = response.body;
          if (resp.code == 0) {
            // this.$Message.success(that.$t("exchange.success"));
            Toast.success("提交成功");
            this.refreshAccount();
            this.sliderBuyMarketPercent = 0;
          } else {
            // this.$Message.error(resp.message);
            Toast.fail(resp.message);
          }
        });
    },
    //限价卖出
    sellLimitPrice() {
      if (this.form.buy.limitAmount == "") {
        // this.$Message.error(this.$t("exchange.sellamounttip"));
        Toast.fail(this.$t('other.translate124'));
        return;
      }
      if (this.form.buy.limitPrice == "") {
        // this.$Message.error(this.$t("exchange.sellpricetip"));
        Toast.fail(this.$t('other.translate128'));

        return;
      }
      if (this.form.buy.limitAmount > parseFloat(this.wallet.coin)) {
        // this.$Message.error("最多能卖" + this.wallet.coin + this.$t('other.translate130'));
        Toast.fail(this.$t('other.translate129') + this.wallet.coin + this.$t('other.translate130'));
        return;
      }
      var params = {};
      params["symbol"] = this.currentCoin.symbol;
      params["price"] = this.form.buy.limitPrice;
      params["amount"] = this.form.buy.limitAmount;
      params["direction"] = "SELL";
      params["type"] = "LIMIT_PRICE";
      params["useDiscount"] = this.isUseBHB ? "1" : "0"; //是否试用手续费抵扣,0 不使用 1使用
      //  params["fundsType"] = "0";
      if (this.exchangeTable === 1) {
        params["fundsType"] = 1;
      } else {
        params["fundsType"] = 0; // 体验金
      }
      var that = this;
      this.$http
        .post(this.host + this.api.exchange.spotOrderAdd, params)
        .then((response) => {
          var resp = response.body;

          if (resp.code == 0) {
            // this.$Message.success(that.$t("exchange.success"));
            Toast.success(this.$t('other.translate123'));
            this.refreshAccount();
            this.form.buy.limitAmount = 0;
            this.sliderSellLimitPercent = 0;
          } else {
            // this.$Message.error(resp.message);
            Toast.fail(resp.message);
          }
        });
    },
    //市价卖出
    sellMarketPrice() {
      if (this.form.buy.limitAmount == "") {
        // this.$Message.error(this.$t("exchange.sellamounttip"));
        Toast.fail(this.$t('other.translate124'));

        return;
      }
      if (this.form.buy.limitAmount > parseFloat(this.wallet.coin)) {
        // this.$Message.error("最多能卖" + this.wallet.coin + "个");
        Toast.fail(this.$t('other.translate129') + this.wallet.coin + this.$t('other.translate130'));
        return;
      }

      var params = {};
      params["symbol"] = this.currentCoin.symbol;
      params["price"] = 0;
      params["amount"] = this.form.buy.limitAmount;
      params["direction"] = "SELL";
      params["type"] = "MARKET_PRICE";
      params["useDiscount"] = this.isUseBHB ? "1" : "0"; //是否试用手续费抵扣,0 不使用 1使用
      if (this.exchangeTable === 1) {
        params["fundsType"] = 1;
      } else {
        params["fundsType"] = 0; // 体验金
      }
      var that = this;
      this.$http
        .post(this.host + this.api.exchange.spotOrderAdd, params)
        .then((response) => {
          var resp = response.body;
          if (resp.code == 0) {
            this.$Message.success(that.$t("exchange.success"));
            Toast.success(this.$t('other.translate123'));
            this.refreshAccount();
            this.sliderSellMarketPercent = 0;
          } else {
            Toast.fail(resp.message);

            // this.$Message.error(resp.message);
          }
        });
    },
    refreshAccount: function () {
      this.getCurrentOrder();
      this.getHistoryOrder();
      this.getWallet();
      this.getMoney();
    },

    getMoney() {
      if (!this.islogin) return;
      this.$http.post(this.host + "/uc/asset/spotWallet/USDT").then((response) => {
        var resp = response.body;
        if (resp.code == 0) {
          this.tableMoney = resp.data;
          console.log("现货余额???????", this.tableMoney);
          this.contractAssetPNL.balance = this.tableMoney.balance;
          this.contractAssetPNL.balanceExperience = this.tableMoney.trialfundsBalance;
        } else {
          // this.$Message.error(this.loginmsg);
          Toast.fail(this.loginmsg);
        }
      });
    },

    /**
     * 获取钱包信息
     */
    getWallet() {
      this.$http
        .post(this.host + this.api.uc.spotWallet + this.currentCoin.base, {})
        .then((response) => {
          var resp = response.body;
          console.log(resp, "respresprespresprespresp");
          this.wallet.base = resp.data.balance || "";
          this.wallet.trialfundsBalance = resp.data.trialfundsBalance || "";
          this.wallet.baseCompany = resp.data.coin.name || "";
          console.log("买入当前币种，可用的USDT：", resp);
        });
      this.$http
        .post(this.host + this.api.uc.spotWallet + this.currentCoin.coin, {})
        .then((response) => {
          var resp = response.body;
          console.log("卖出当前币种，可用的当前币种：", resp);
          this.wallet.coin = "";
          this.wallet.coinCompany = "";
          this.wallet.cointrialfundsBalance = "";
          this.wallet.cointrialfundsBalance = resp.data.trialfundsBalance || "";
          this.wallet.coinCompany = resp.data.coin.name;
          this.wallet.coin = resp.data.balance || "";
          console.log(this.wallet.coinCompany, "this.wallet.coinCompany");
        });
    },
    cancel(index) {
      var order = this.currentOrder.rows[index];
      console.log("order", order);
      // let order = row;
      this.$http
        .post(
          this.host + this.api.exchange.spotOrderCancel + "/" + order.orderId,
          {}
        )
        .then((response) => {
          let resp = response.body;
          if (resp.code == 0) {
            this.refreshAccount();
            this.$Message.success(this.$t('other.translate131'));
          } else {
            this.$Message.error(resp.message);
          }
        });
    },
    getCurrentOrder() {
      //查询当前委托
      let params = {};
      params["pageNo"] = 1;
      params["pageSize"] = 20;
      params["symbol"] = this.currentCoin.symbol;
      this.currentOrder.rows = [];
      let that = this;
      this.currentLoading = true;
      this.paramsIsaac = params;
      this.$http
        .post(this.host + this.api.exchange.spotOrderCurrent, params)
        .then((response) => {
          let resp = response.body;
          console.log("当前委托：", resp);

          this.paramsIsaac = { ...this.paramsIsaac, total: resp.totalElements };

          if (resp.content && resp.content.length > 0) {
            this.currentOrder.rows = resp.content.slice(0, resp.totalElements);
            this.currentOrder.rows.forEach((row, index) => {
              row.skin = that.skin;
              row.price = row.type == "MARKET_PRICE" ? "市价" : row.price;
            });
          }
          this.currentLoading = false;
        });
    },
    getHistoryOrder(pageNo) {
      //查询历史委托
      // if (pageNo == undefined) {
      //     pageNo = this.historyOrder.page;
      // } else {
      //     pageNo = pageNo - 1;
      // }
      this.historyOrder.rows = []; //清空数据
      let params = {};
      params["pageNo"] = 1;
      params["pageSize"] = this.historyOrder.pageSize;
      params["symbol"] = this.currentCoin.symbol;
      let that = this;
      this.historyLoading = true;
      this.$store.state.loadIsaac = false;

      this.paramsIsaacAll = params;

      this.$http
        .post(this.host + this.api.exchange.spotOrderHistory, params)
        .then((response) => {
          let resp = response.body;
          let rows = [];
          console.log("历史委托", resp);

          this.paramsIsaacAll = {
            ...this.paramsIsaacAll,
            total: resp.totalElements,
          };

          if (resp.content != undefined) {
            if (resp.content.length > 0) {
              this.historyOrder.total = resp.totalElements;
              this.historyOrder.page = resp.number;
              // historyOrderhistoryOrder
              for (let i = 0; i < resp.totalElements; i++) {
                let row = resp.content[i];
                if (row) {
                  row.skin = that.skin;
                  row.price =
                    row.type == "MARKET_PRICE"
                      ? that.$t("exchange.marketprice")
                      : row.price;
                  // this.historyOrder.rows.push(row);
                  rows.push(row);
                }
              }
              console.log(rows, "rowssssssssssss");
              this.historyOrder.rows = rows;
            }
            this.historyLoading = false;
          }
        });
    },
    onConfirm(value, index) {
      this.showPicker = false;
      this.showMAIOrMAI = index;
    },
    sliderChange(value) {
      // this.form.buy.limitAmount = (
      //   this.contractAssetPNL.balance *
      //   (value / 100)
      // ).toFixed(2);
      if (this.WTValue === "limit") {
        this.form.buy.limitAmount = (
          (this.contractAssetPNL.balance / this.plate.price) *
          (value / 100)
        ).toFixed(2);
      } else {
        this.form.buy.limitAmount = (
          this.contractAssetPNL.balance *
          (value / 100)
        ).toFixed(2);
      }
    },
    exchangeCut(type) {
      this.exchangeTable = type;
    },
    goToLogin() {
      // 去登陆
      this.$router.push({
        name: "signin",
        // query: {
        //   path: "secondContract/" + that.coin + "_" + that.market,
        // },
      });
    },
    doModifyStop(order, val) {
      window.addEventListener("scroll", this.move);
      this.modifySelectOrder = order;
      // if (val == "tska") {
      //   this.showModifyStop = false;
      //   this.modifyStopProfitLoss(this.modifySelectOrder);
      // } else {
      this.form.modifyStop.stopProfitPrice = order.stopProfitPrice;
      this.form.modifyStop.stopLossPrice = order.stopLossPrice;
      this.showModifyStop = true;
      // }
    },
    modifyStopProfitLoss(order) {
      this.showModifyStop = false;

      let param = {
        orderId: this.modifySelectOrder.orderId,
        stopProfitPrice: this.form.modifyStop.stopProfitPrice,
        stopLossPrice: this.form.modifyStop.stopLossPrice,
      };
      //获取杠杆
      // console.log("订单", this.modifySelectOrder);
      this.$http
        .post(this.host + this.api.exchange.modifyStopProfitLoss, param)
        .then((response) => {
          let result = response.body;
          if (result.code == 0) {
            this.$Message.success(this.$t('other.translate132'));
          }
        });
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp); // 注意时间戳通常是秒为单位，需要乘以1000转为毫秒
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // getMonth() 是从0开始的
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day
        } ${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes
        }:${seconds < 10 ? "0" + seconds : seconds}`;
    },
  },
};
</script>
<style>
.btn {
  background-color: #000;
  border: none;
  height: 8.1425vw;
  border-radius: 1.0178vw;
  width: 91.6031vw;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  line-height: 8.1425vw;
}

.van-stepper {
  height: 7.3791vw !important;
}

.van-dropdown-menu {
  height: 7.3791vw !important;
}

.van-dropdown-menu {
  height: 7.3791vw !important;
}

.tradeSubmitMore {
  width: 41.7303vw;
  height: 8.1425vw;
  background-color: #000000;
  border: none;
  border-radius: 1.0178vw;
  font-family: Inter;
  font-size: 4.0712vw;
  font-weight: 500;

}

.van-dropdown-menu__title {
  margin-top: 1.2723vw !important;
}

.van-stepper__input {
  height: 29px !important;
  background-color: transparent !important;
  border: none !important;
}

.greenBox {
  font-size: 2.5712vw;
  width: 9.6vw;
  margin-left: 3vw;
  background: rgba(22, 199, 132, 0.2);
  color: rgba(22, 199, 132, 0.5);
}

.tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
}

.tagBox {
  width: 80px;
  height: 32px;
  background-color: #F6F6F6;
  border-radius: 4px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #808080;

}

.active {
  border: 1px solid #000000;
  color: #000000;
}

.redBox {
  font-size: 2.5712vw;
  width: 9.6vw;
  margin-left: 3vw;
  background: rgba(234, 57, 67, 0.2);
  color: #ea3943;
}

.tradeLeftL {
  width: 22vw;
}

.tradeLeftr {
  width: 22vw;
}

.trThead {
  padding-right: 4vw;
}

.trThead span {
  font-size: 2.9333vw;
  white-space: nowrap;
  color: #999;
}

.leftBoxs {
  height: 33vh;
  overflow-y: auto;
}

.leftBoxH {
  height: 29vh;
  overflow-y: auto;
}

.leftBoxHs {
  height: 25vh;
  overflow-y: auto;
}

.ZXT {
  padding: 1vw 0;
  /* background: green; */
}

.leftBoxHTWO {
  height: 15vh;
  overflow-y: auto;
}

.van-dropdown-menu__title .van-ellipsis {
  font-size: 3vw;
  font-family: SF Pro;
  font-weight: 900;
}

.van-dropdown-menu__title:after {
  top: 1.4vh;
  right: 3.6vw;
}

.van-dropdown-menu__title {
  background: #eff0f2;
  /* width: 28vw; */
  width: 36vw;
  /* padding: 0.4vh; */
}

.icoBox {
  margin-left: 2vw;
}

.van-stepper {
  display: flex;
  flex-wrap: nowrap;
  height: 3vh;
}

.tradeRight {
  overflow: hidden;
  padding-right: 2vw;
}

.van-stepper__input {
  height: 3vh !important;
}

.bc {
  background: #eff0f2;
  margin-bottom: 1vh;
  display: flex;
  align-items: center;
}

.van-dropdown-menu,
.van-dropdown-menu__bar {
  background: #eff0f2;
  height: 3vh;
  box-shadow: none;
}

::v-deep .disPadd .van-field {
  height: 3vh;
  line-height: 3vh;
  padding: 0vw !important;
  width: 77%;
}

::v-deep .disPadd .van-field__body {
  height: 3vh;
  line-height: 3vh;
}

::v-deep .disPadd input[type="text"] {
  margin: 0;
  background: transparent;
  border: none !important;
}

input[type="number"] {
  margin: 0;
  background: #eff0f2;
  height: 3vh;
  border: none !important;
}

.textADA {
  color: #616e85;
  font-size: 3vw;
  font-family: SF Pro;
  font-weight: 900;
  padding-right: 2vw;
}

.disBew {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #616e85;
  font-weight: 510;
  font-size: 3vw;
  font-family: SF Pro;
}

.disBew2 {
  overflow-x: auto;
  margin-bottom: 1vh;
}

.hh {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #616e85;
  font-weight: 510;
  font-size: 3vw;
  font-family: SF Pro;
  margin-top: 2vw;
  width: 90vw;
}

.tradeSlider .sliderNum {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  top: 5vw;
  left: 0;
  right: 0;
}

.martop {
  margin-top: 1vh;
}

.martop2 {
  margin-top: 0.4vh;
}

.van-button--large {
  height: 4vh;
  line-height: 4vh;
  color: #fff;
}

.bor1 {
  width: 10vw;
  height: 3vh;
  line-height: 3vh;
  text-align: center;
  border: 0.1vw solid #1789fa;
  color: #1789fa;
  border-radius: 4px;
}

.activeBGC {
  width: 10vw;
  height: 3vh;
  line-height: 3vh;
  text-align: center;
  border: 0.1vw solid #1789fa;
  border-radius: 4px;
  background: #1789fa;
  color: #fff;
}

.zsjBox {
  width: 100%;
  border: 0.1vw solid #1789fa;
  margin-top: 0.8vh;
  padding: 0.8vw 2vw;
  color: #616e85;
  font-weight: 510;
  font-family: SF Pro;
  font-size: 3vw;
  box-sizing: border-box;
}

.zsj {
  color: #0d1421;
}

.disCenter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.disB {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.sd5 {
  margin-top: 0.4vh;
  width: 100%;
  color: #616e85;
  font-weight: 510;
  font-family: SF Pro;
  font-size: 3vw;
  box-sizing: border-box;
}

.imgSD {
  width: 5vw;
  height: 2.5vh;
}

img {
  width: 100%;
  height: 100%;
}

.dqcc {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: center;
  color: #000 !important;
  position: relative;
}

.dqccLine {
  position: absolute;
  background: #000;
  width: 40px;
  height: 3px;
  margin-top: 1vw;
  border-radius: 0.3333vw;
  top: 2vh;
  left: 0.8vw;
}

.tradeSlider .vslider .van-slider {
  z-index: 1;
}

.hqlb-text {
  font-size: 3.4vw;
  color: #f72a26;
  font-weight: bold;
}

.hqlb-text-green {
  font-size: 3.4vw;
  color: green;
  opacity: 0.5;
  font-weight: bold;
}

.asmallicon {
  margin-left: 2vw;
  width: 1vw;
  height: 1.6vh;
}

.login {
  width: 100%;
  padding: 0 10vw;
}

.forTitleBox {
  width: auto;
  min-width: 23px;
  height: 16px;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  border-radius: 10px;
  color: #fff;
  padding: 0 5px;
  margin-left: 8px;
  background: linear-gradient(114.88deg, #000000 42.57%, #7C7C7C 94.06%);
}

.order {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  text-align: left;
  color: #000;
  margin: 24px 0 8px 0;
}

.time {
  display: flex;
  justify-content: space-around;
}

.dis {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.numALl {
  width: 20vw;
  height: 3vh;
  /* background: #16c784; */
  color: #fff;
  text-align: center;
  line-height: 3vh;
  border-radius: 15px;
}

.xgButton {
  width: 300px;
  height: 30px;
  font-size: 3vw;
  border-radius: 4px;
  border: 1px solid #000;
  border-radius: 4px;
  color: #000;
  text-align: center;
  line-height: 30px;

}

.disPadd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5vw;
}

.redBg {
  background: #FA4940;
  color: #fff;
  text-align: center;
}

.greenBg {
  background: #3AD29F;
  color: #fff;
  text-align: center;
}

.li {
  width: 343px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  border: 1px solid #0000001A;
  margin-bottom: 8px;
  margin-top: 8px;
  padding: 16px 0 0 16px;
}

.time {
  width: 100%;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  color: #00000080;
  margin-top: -24px;
  display: flex;
  justify-content: flex-start;
}

.boxOne {
  width: 33%;
  text-align: center;
  margin-bottom: 1vh;
}

.boxOne1 {
  width: 33%;
  text-align: center;
  margin-bottom: 1vh;
}

.boxOne div:first-child {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: #00000080
}

.boxOne div:last-child {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #000
}

.forTitle {
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 2vh;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;

}

.type {
  width: 80px;
  height: 31px;
  background-color: #000;
  border-radius: 4px;
  margin-right: 4vw;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 31px;
  text-align: center;
  margin-left: 145px
}

.boxOne1 {
  width: 33%;
  text-align: center;
  margin-bottom: 1vh;
}

/* .types {
  margin-right: 4vw;
  color: #16c784;
} */
.types {
  margin-right: 4vw;
  color: red;
}

.disBew2::-webkit-scrollbar,
.disBew2::-webkit-scrollbar-horizontal,
.disBew2::-webkit-scrollbar-thumb {
  display: none;
}

.leftBoxs::-webkit-scrollbar,
.leftBoxs::-webkit-scrollbar-horizontal,
.leftBoxs::-webkit-scrollbar-thumb {
  display: none;
}

.leftBoxH::-webkit-scrollbar,
.leftBoxH::-webkit-scrollbar-horizontal,
.leftBoxH::-webkit-scrollbar-thumb {
  display: none;
}

.leftBoxHTWO::-webkit-scrollbar,
.leftBoxHTWO::-webkit-scrollbar-horizontal,
.leftBoxHTWO::-webkit-scrollbar-thumb {
  display: none;
}

.disPadd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5vw;
}

.redBg {
  background: red;
}

.greenBg {
  background: #16c784;
}

.reds {
  color: red;
}

.greens {
  color: #16c784;
}
</style>
