<template>
  <div class="drawPage">
    <van-popup
      v-model="$store.state.app.drawer.show"
      position="left"
      :duration="0.3"
    >
      <div class="drawHead flexBothSidesVertical">
        <!-- <span :style="{ color: '#000' }">{{ chooseType }}</span> -->
        <div class="asmallicon2">
          <!-- <img src="../../assets/img/logo.png" alt="" /> -->
        </div>
        <i
          :style="{ color: '#000', 'font-size': '6vw' }"
          class="iconfont iconlist_left"
          @click="$store.state.app.drawer.show = false"
        ></i>
        <!-- <van-icon name="bars" @click="$store.state.app.drawer.show = false" /> -->
      </div>
      <div class="au">
        <div class="secondMenus quotesB">
          <!-- 内容切换 -->
          <div class="drawContent">
            <nodata v-if="!coinsList || coinsList.length == 0"></nodata>

            <ul class="drawUl" v-if="coinsList && coinsList.length > 0">
              <li class="flexSpindleVertical">
                <span>{{ $t('other.translate69') }}</span>
                <span>{{ $t('other.translate27') }}</span>
                <span>{{ $t('other.translate82') }}</span>
              </li>
              <li
                class="flexSpindleVertical"
                v-for="(item, index) of coinsList"
                :key="index"
                @click="getint(item.symbol)"
              >
                <div class="BTCPrice">
                  <div class="asmallicon2">
                    <img src="../../assets/img/icon_bite.png" alt="" />
                  </div>
                  {{ item.symbol.split("/")[0] }}
                </div>
                <div
                  class="disCom"
                  :class="{
                    redText: item.change < 0,
                    greenText: item.change >= 0,
                  }"
                >
                  <div class="BTCPrice">
                    {{ item.price }}
                    <!-- <div class="asmallicon">
                      <img
                        src="../../assets/img/icon_fall.png"
                        alt=""
                        v-if="item.change < 0"
                      />
                      <img
                        src="../../assets/img/icon_rise.png"
                        alt=""
                        v-if="item.change >= 0"
                      />
                    </div> -->
                  </div>
                  <!-- <div :style="{ color: '#616E85' }">
                    ≈ ￥ {{ item.cnyPrice }}
                  </div> -->
                </div>
                <div
                  class="BTCButton"
                  :class="{
                    red: item.change < 0,
                    green: item.change >= 0,
                  }"
                >
                  {{ item.rose }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  watch: {},
  computed: {
    // chooseType() {
    //   switch (this.$store.state.app.trade.chooseType) {
    //     case 0:
    //       return "合约";
    //     case 1:
    //       return "币币";
    //     default:
    //       return "合约";
    //   }
    // },
    coinsList() {
      return this.$store.state.coinsList;
    },
  },
  mounted() {},
  methods: {
    getint(symbol) {
      console.log("stybol", symbol);
      this.$store.state.app.drawer.toptype = symbol;
      this.$store.state.app.drawer.show = false;
    },
  },
};
</script>
<style>
.au {
  height: 100%;
  overflow-y: auto;
}
.darkModel .drawPage .van-popup {
  background: #fff;
}
.drawPage .van-popup {
  width: 82.3333vw;
}
.flexSpindleVertical {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 3vw;
}
.darkModel .secondMenus .drawContent .drawUl li > b,
.darkModel .secondMenus .drawContent .drawUl li > span {
  color: #000;
}
.BTCButton {
  width: 16vw;
  height: 3vh;
  color: #fff;
  text-align: center;
  line-height: 3vh;
}
.green {
  color: green;
  font-size: 14px;
}
.red {
  color: red;
  font-size: 14px;
}
.redText {
  color: #000;
  font-size: 14px;
}
.greenText {
  color: #000;
  font-size: 14px;
}
.BTCPrice {
  width: 30vw;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.asmallicon {
  margin-left: 2vw;
  width: 1vw;
  height: 1.6vh;
}
.asmallicon2 {
  margin-right: 2vw;
  width: 6vw;
  height: 6vw;
}
.darkModel .drawHead {
  background: #fff;
}
.disCom {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}
</style>
